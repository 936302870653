import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import '../../stylesheets/comic.css'
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ComicEpisode(){
  const [episode, setEpisode] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  const { series, episodeId } = useParams()

  const getResultsData = async () => {
    if(isLoading){  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/byEpisode/${series}/${episodeId}`)
      .then((response) => response.json({}))
      .then((response) => setEpisode(response))
      .then((n) => setLoading(false))
      .catch((err) => {
        setError(true)
      });
    }
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    lazyLoad: true,
    accessibility: true
  };

  var mobileSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    lazyLoad: true,
    accessibility: true
  };

  useEffect(() => {
    getResultsData();
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  if (isLoading === true) return 'Loading...'
  if (error) return 'Error has occurred'
  if (episode["Format"] == "horizontal"){
    if (windowSize[0] > 900){
      return (
        <div class="comicContainer" >
          <Helmet>
            <title>Mythos Makers - {episode["Title"]}</title>
            <meta name="description" content={episode["Title"]} />
          </Helmet>
          <Slider {...settings} className="comicSlider" >
          {Object.keys(episode["Pages"]).map((key, i) => (
            <div>
              <div class="pageContainer">
                <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][key]}`} alt="" class="horizontalPage" />
              </div>
            </div>
          ))}
          </Slider>
        </div>
      )
    }else{
      return (
        <div class="comicContainer" >
          <Helmet>
            <title>Mythos Makers - {episode["Title"]}</title>
            <meta name="description" content={episode["Title"]} />
          </Helmet>
          <Slider {...mobileSettings} className="comicSlider" >
          {Object.keys(episode["Pages"]).map((key, i) => (
            <div class="pageContainer">
              <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][key]}`} alt="" class="horizontalPage" />
            </div>
          ))}
          </Slider>
        </div>
      )
    }
  }else{
    return(
      <div class="comicContainer" >
        <Helmet>
          <title>Mythos Makers - {episode["Title"]}</title>
          <meta name="description" content={episode["Title"]} />
        </Helmet>
        <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][0]}`} alt="" class="verticalPage" />
      </div>
    )
  }
  
}

export default ComicEpisode;